$(document).ready(function() {

    // Menü-Icon animation auslösen
    $('.mainnav__menu-icon').click(function () {
        $(this).toggleClass('open');
        $('.mainnav__list1').slideToggle('fast');
    });


    // Custom mobile nav
    $(".icon-unfold").click(function(){
        $(this).toggleClass("icon-unfold--down");
        $(this).parents().closest('li').find('has-sub').addClass("open");
        var dropdown = $(this).next();
        dropdown.slideToggle();
    });


    // Zum Seitenanfang scrollen
    $('.footer__scroll2top').click(function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });

    // Mainpic Slider initialisieren
    if ($('.mainpic--slider').length) {
        $('.mainpic--slider').slick({
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000
        });
    }


    // Cards Slider initialisieren
    if ($('.cards--slider').length) {
        $('.cards--slider .cards__itemlist').slick({
            dots: true,
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }


    // Lightgallery initialisieren
    if ($(".lightgallery").length) {
        $(".lightgallery").lightGallery({
            selector: '.imagegallery__linkitem',
            download: false
        });
    }


    // Referenzslider Slider initialisieren
    if ($('.refslider__itemlist').length) {
        $('.refslider__itemlist').slick({
            dots: false,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false
        });
    }


    $('.contact__form input, .contact__form textarea').blur(function() {
        if($(this).val()) {
            $(this).addClass('hasUserInput');
        }
    });


    // Horizontale Navigation anpinnen
    var header = $('header');
    var headerHeight = header.outerHeight();
    $(window).scroll(function(){
        var scrollPosToTop = $(this).scrollTop();
        if (scrollPosToTop > headerHeight){ header.addClass('fixed'); }
        else { header.removeClass('fixed'); }
    });


    // DoubleTapToGO
    (function ($, window, document, undefined) {
        'use strict';
        var pluginName = 'doubleTapToGo',
            defaults = {
                automatic: true,
                selectorClass: 'doubletap',
                selectorChain: 'li:has(ul)'
            };

        function DoubleTapToGo (element, options) {
            this.element = element;
            this.settings = $.extend({}, defaults, options);
            this._defaults = defaults;
            this._name = pluginName;
            this.init();
        }

        $.extend(DoubleTapToGo.prototype, {
            preventClick: false,
            currentTap: $(),
            init: function () {
                $(this.element)
                    .on('touchstart', '.' + this.settings.selectorClass, this._tap.bind(this))
                    .on('click', '.' + this.settings.selectorClass, this._click.bind(this))
                    .on('remove', this._destroy.bind(this));

                this._addSelectors();
            },

            _addSelectors: function () {
                if (this.settings.automatic !== true) {
                    return;
                }
                $(this.element)
                    .find(this.settings.selectorChain)
                    .addClass(this.settings.selectorClass);
            },

            _click: function (event) {
                if (this.preventClick) {
                    event.preventDefault();
                } else {
                    this.currentTap = $();
                }
            },

            _tap: function (event) {
                var $target = $(event.target).closest('li');
                if (!$target.hasClass(this.settings.selectorClass)) {
                    this.preventClick = false;
                    return;
                }
                if ($target.get(0) === this.currentTap.get(0)) {
                    this.preventClick = false;
                    return;
                }
                this.preventClick = true;
                this.currentTap = $target;
                event.stopPropagation();
            },

            _destroy: function () {
                $(this.element).off();
            },

            reset: function () {
                this.currentTap = $();
            }
        });

        $.fn[pluginName] = function (options) {
            var args = arguments,
                returns;
            if (options === undefined || typeof options === 'object') {
                return this.each(function () {
                    if (!$.data(this, pluginName)) {
                        $.data(this, pluginName, new DoubleTapToGo(this, options));
                    }
                });
            } else if (typeof options === 'string' && options[0] !== '_' && options !== 'init') {
                this.each(function () {
                    var instance = $.data(this, pluginName),
                        methodName = (options === 'destroy' ? '_destroy' : options);
                    if (instance instanceof DoubleTapToGo && typeof instance[methodName] === 'function') {
                        returns = instance[methodName].apply(instance, Array.prototype.slice.call(args, 1));
                    }
                    if (options === 'destroy') {
                        $.data(this, pluginName, null);
                    }
                });
                return returns !== undefined ? returns : this;
            }
        };
    })(jQuery, window, document);

    $('.mainnav').doubleTapToGo();

});
